
















import store from '@/store';
import ThreadResource from '@/types/ThreadResource';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    thread: {
      type: Object as PropType<ThreadResource>,
      required: true,
    },
  },

  // created(): void {
  //   store.dispatch('reifyResource', {
  //     resource: this.thread,
  //     useCached: true,
  //   });
  // },

  computed: {
    participantUsernames(): string {
      const { participantUsernames } = this.thread.attributes;
      const { username } = store.state.user.attributes;
      const otherParticipants = participantUsernames.filter(pu => pu !== username);
      return otherParticipants.join(', ');
    },

    latestMessageBody(): string {
      // const { participantUsernames } = this.thread.attributes;
      // const { username } = store.state.user.attributes;
      // const otherParticipants = participantUsernames.filter(pu => pu !== username);
      // return otherParticipants.join(', ');
      return this.thread.attributes.latestMessageBody;
    },

    messageCount(): number {
      return this.thread.relationships.messages.data.length;
    },
  },
});
