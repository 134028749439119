












import Vue, { PropType } from 'vue';
import ThreadListItem from '@/components/threads/ThreadListItem.vue';
import ThreadResource from '@/types/ThreadResource';

export default Vue.extend({
  components: {
    ThreadListItem,
  },

  props: {
    threads: {
      type: Array as PropType<ThreadResource[]>,
      required: true,
    },
  },

  methods: {
    onThreadSelected(id: string): void {
      this.$router.push({ name: 'ThreadShow', params: { id } });
    },
  },
});
